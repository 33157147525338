import React, { useEffect } from "react";
import { Theme } from "@radix-ui/themes";
import "./App.scss";
import "./app/globals.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import favicon from "./Images/BlobFavicon.png";
import posthog from "posthog-js";

// Initialize PostHog
posthog.init("phc_FisJ14EKKhGRMLuqt7lRICrXczWIs94kb3hXhnOWMfP", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "identified_only",
});

function App() {
  useEffect(() => {
    const setFavicon = () => {
      const link = (document.querySelector("link[rel*='icon']") ||
        document.createElement("link")) as HTMLLinkElement;
      link.type = "image/png";
      link.rel = "icon";
      link.href = favicon;
      document.getElementsByTagName("head")[0].appendChild(link);
    };

    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7797505693357459";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);

    setFavicon();
  }, []);

  return (
    <div className="App">
      <Router>
        <Theme appearance="dark">
          <div id={"app-container"}>
            <Routes />
          </div>
        </Theme>
      </Router>
    </div>
  );
}

export default App;
